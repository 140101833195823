import React from "react";
import "../styles/VehicleCard.css";

const VehicleCard = ({ vehicle, onSelect }) => {
  const { id, name, type, level, health, damage, image, image1, image2, image3, isSelected } = vehicle;

  return (
    <div className={`vehicle-card ${isSelected ? "selected" : ""}`}>
	
	<table border="0" style={{ width: '100%', borderCollapse: 'collapse' }}>
	<tbody>
          <tr>
            <td colSpan="2">ТЕХНИКА</td>
			<td>ЭКИПАЖ</td>
          </tr>
          <tr>
            <td style={{ padding: '40px', width: '90%' }} colSpan="2"  rowSpan="3"><img src={image} alt="" className="vehicle-image" /></td>
            <td style={{ padding: '10px', width: '10%' }} ><img src={image1} alt="" className="vehicle-image1" /></td>
          </tr>
          <tr>
            <td style={{ padding: '10px', width: '10%' }} ><img src={image2} alt="" className="vehicle-image2" /></td>
          </tr>
		  <tr>
            <td style={{ padding: '10px', width: '10%' }} ><img src={image3} alt="" className="vehicle-image3" /></td>
          </tr>
        </tbody>
      </table>
	  <br />
	  <table border="0" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th colSpan="3">{name}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: '30%' }} colSpan="2">Тип:</td>
            <td>{type}</td>
          </tr>
          <tr>
            <td colSpan="2">Уровень:</td>
            <td>{level}</td>
          </tr>
          <tr>
            <td colSpan="2">Здоровье:</td>
            <td>{health}%</td>
          </tr>
		  <tr>
            <td colSpan="2">Урон:</td>
            <td>{damage}</td>
          </tr>
        </tbody>
      </table><br />
      <button onClick={() => onSelect(id)}>
        {isSelected ? "Отменить выбор" : "Выбрать"}
      </button>
    </div>
  );
};

export default VehicleCard;