import React, { useState } from "react";
import { vehicles } from "../data";
import VehicleCard from "./VehicleCard";
import "../styles/Hangar.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Hangar = () => {
  const [vehiclesList, setVehiclesList] = useState(vehicles);

  const handleSelect = (id) => {
    setVehiclesList((prevVehicles) =>
      prevVehicles.map((vehicle) =>
        vehicle.id === id
          ? { ...vehicle, isSelected: !vehicle.isSelected }
          : vehicle
      )
    );
  };
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="hangar">
      <Slider {...settings}>
    {vehiclesList.map((vehicle) => (
      <div key={vehicle.id}>
        <VehicleCard vehicle={vehicle} onSelect={handleSelect} />
      </div>
    ))}
  </Slider>
    </div>
  );
};
console.log(vehicles);
export default Hangar;