import React from "react";
import './App.css';
import Hangar from "./Components/Hangar";
import Footer from "./Components/Footer";
import "./styles/Hangar.css";
import "./styles/Footer.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="block top-block">
          Верхний блок
        </div>
<div className="container-с">
      <div className="block-с">Блок 1</div>
      <Hangar />
      <div className="block-с">Блок 3</div>
    </div>
		
		
        <div className="block bottom-block">
          Нижний блок
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default App;

