export const vehicles = [
  {
    id: 1,
    name: "Т-34",
    type: "Танк",
    level: 5,
    health: 100,
    damage: 75,
    image: "https://xgame.su/images/t34.png",
	image1: "https://xgame.su/images/com.png",
	image2: "https://xgame.su/images/com.png",
	image3: "https://xgame.su/images/com.png",
    isSelected: false,
  },
  {
    id: 2,
    name: "ИС-2",
    type: "Танк",
    level: 7,
    health: 85,
    damage: 120,
    image: "https://xgame.su/images/t34.png",
    isSelected: false,
  },
  {
    id: 3,
    name: "Тигр",
    type: "Танк",
    level: 8,
    health: 90,
    damage: 110,
    image: "https://xgame.su/images/t34.png",
    isSelected: false,
  },
];